.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.doc-thumb {
  width: calc(80vw/10);
  height: calc(80vw/20);
  border-radius: 25%;
}

.doc-thumb > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.doc-thumb > img:hover {
  filter: brightness(75%);
}
