@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

button:disabled {
  cursor: default !important;
}

@font-face {
  font-family: 'Futuracrunch';
  src: url(/static/media/FuturacrunchThin.b80b7aaf.woff2) format('woff2'),
  url(/static/media/FuturacrunchThin.cba51d68.woff) format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FuturacrunchCond';
  src: url(/static/media/FuturacrunchCondRegular.21b1b9ab.woff2) format('woff2'),
  url(/static/media/FuturacrunchCondRegular.59227200.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futuracrunch';
  src: url(/static/media/FuturacrunchRegular.ae588ef0.woff2) format('woff2'),
  url(/static/media/FuturacrunchRegular.e3a9cc9f.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.screen::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: repeating-linear-gradient(
          0deg,
          rgba(0,0,0, 0.15),
          rgba(0,0,0, 0.15) 1px,
          transparent 1px,
          transparent 2px
  );
}

.underline-light > input {
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  font-family: Futuracrunch, serif;
  font-size: calc(80vw/40);
  color: white;
  text-align: left;
}


/* MISSION CONTROL POPUP STYLING */
.outline-light > input {
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  letter-spacing: 1.25em;
  margin-left: 0.625em;
  font-family: Futuracrunch, serif;
  font-size: calc(80vw/40);
  color: white;
  text-align: center;
}
.outline-light#code-input {
  background: none;
  border: 3px solid white;
  padding: calc(80vw/100);
  max-width: calc(80vw/4);
}
.outline-light > input:focus,
.underline-light > input:focus {
  border: none !important;
  outline: none !important;
}

.outline-light {
  background: none;
  color: white;
  border: 3px solid white;
}
.underline-light {
  color: white;
  border-bottom: 6px solid white;
}
.btn-outline-light {
  position: relative;
  background: none;
  color: white;
  border: 3px solid white;
  cursor: pointer;
}
.btn-outline-light[data-selected="true"] {
  background: white;
  color: black;
  border: 3px solid white;
  cursor: default;
}
.btn-outline-light:disabled {
  color: #626262;
  border: 3px solid #626262;
  cursor: default;
}
.btn-outline-light:disabled::after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  pointer-events: none;
  background: repeating-linear-gradient(
          30deg,
          rgba(0,0,0, 0.4),
          rgba(0,0,0, 0.4) 1px,
          transparent 1px,
          transparent 2px
  );
}
.btn-outline-light:active {
  background: #c4c4c4 !important;
  color: black;
  border: 3px solid #c4c4c4 !important;
}
.btn-outline-light[data-selected="true"]:hover  {
  background: #c4c4c4;
  color: black;
  border: 3px solid #c4c4c4;
  cursor: default;
}
.btn-outline-light:disabled:hover {
  background: none !important;
  color: #626262;
  border: 3px solid #626262;
}
.btn-outline-light:hover {
  background: white;
  color: black;
  border: 3px solid white;
}

.faketerminal {
  flex: 1 1;
  background: transparent;
  color: inherit;
  border: 0;
  outline: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: -1rem -1rem -1rem -2rem;
  padding: 1rem;
  text-shadow: rgba(0, 255, 0, 0.6) 0px 0px 12px, rgba(0, 255, 0, 0.4) 0px -5px 19px, rgba(168, 255, 128, 0.3) 0px 0px 4px, rgba(0, 255, 0, 0.2) 0px 5px 19px, rgba(101, 80, 57, 0.2) -5px 0px 19px, rgba(98, 37, 104, 0.2) 5px 0px 19px;
  text-indent: 1rem;
}

.cursor {
  opacity: 1;
  background-color: rgb(0, 255, 0);
  -webkit-animation: blink 1s infinite;
          animation: blink 1s infinite;
  box-shadow: rgba(0, 255, 0, 0.6) 0px 0px 12px, rgba(0, 255, 0, 0.4) 0px -5px 19px, rgba(168, 255, 128, 0.3) 0px 0px 4px, rgba(0, 255, 0, 0.2) 0px 5px 19px, rgba(101, 80, 57, 0.2) -5px 0px 19px, rgba(98, 37, 104, 0.2) 5px 0px 19px;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0; }
  49% {
    opacity: 0; }
  50% {
    opacity: 1; } }

@keyframes blink {
  0% {
    opacity: 0; }
  49% {
    opacity: 0; }
  50% {
    opacity: 1; } }


#capcomBtn {
  background-image: url(/static/media/btn1_glow.ba2cbb58.png);
}
#capcomBtn:disabled {
  background-image: url(/static/media/btn1.b64a3e89.png);
}

#statusBtn {
  background-image: url(/static/media/btn2_glow.7c3968da.png);
}
#statusBtn:disabled {
  background-image: url(/static/media/btn2.53bb744c.png);
}

#landingBtn {
  background-image: url(/static/media/btn3_glow.562a11b5.png);
}
#landingBtn:disabled {
  background-image: url(/static/media/btn3.1fae0c76.png);
}

#goBtn {
  background-image: url(/static/media/btn4_glow.c435597c.png);
}
#goBtn:disabled {
  background-image: url(/static/media/btn4.09711a0a.png);
}

#panicBtn {
  background-image: url(/static/media/btn5_glow.47873e1f.png);
}
#panicBtn:disabled {
  background-image: url(/static/media/btn5.7d122bb3.png);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.doc-thumb {
  width: calc(80vw/10);
  height: calc(80vw/20);
  border-radius: 25%;
}

.doc-thumb > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.doc-thumb > img:hover {
  -webkit-filter: brightness(75%);
          filter: brightness(75%);
}

